<template>
  <div class="read-me marks">
    <div class="readMe">
      <p class="title">集卡大挑战限时抢:</p>
      <p>1、活动时间:即日--2023年12月31日。</p>
      <p>
        2、活动入口:扫描可复美产品防伪二维码，输入后四位验证码，点击“集卡大挑战”，进入活动页面。
      </p>
      <p>
        3、活动内容:抽取卡获得文字，集齐“可复美”、“五星可复美”卡，可合成兑换奖励。
      </p>
      <p>4、活动奖励：</p>
      <p>金卡：同时集齐“五-星-可-复-美”五种卡</p>
      <p>可合成金卡兑换奖励。</p>
      <p>银卡：同时集齐“可-复-美”三种卡可合成</p>
      <p>银卡兑换奖励。</p>
    </div>
    <!-- 关闭按钮 -->
    <div class="close" @click="hideReadMe"></div>
  </div>
</template>

<script>
export default {
  methods: {
    hideReadMe() {
      this.$emit("showMyReadeMe");
    },
  },
};
</script>

<style lang="scss" scoped>
.marks {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  .readMe {
    width: 8.32rem;
    height: 11.26rem;
    box-sizing: border-box;
    padding: 0.78rem;
    background-image: url("@/assets/images/readMe_03.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 3rem auto;
    margin-bottom: 0;
    p {
      font-size: 0.32rem;
      color: #120006;
      line-height: 0.65rem;
    }
    .title {
      margin-top: 0.76rem;
      font-size: 0.38rem;
      line-height: 0.7rem;
    }
  }
  .close {
    width: 0.76rem;
    height: 0.76rem;
    margin: 0 auto;
    margin-top: 0.4rem;
    background-image: url("@/assets/images/readMeClose.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>
