import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/exchange',
    name: 'exchange',
    component: () => import('@/views/exchange.vue'),
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('@/views/address.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
  },

  {
    path: '/404',
    name: 'NotFound',
    component: () => import('@/views/NotFound'),
  },
  {
    path: '*',
    redirect: '/404'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
