<template>
  <div class="my-prizes marks">
    <div class="prizes">
      <h3>我的奖品</h3>
      <!-- prizes 有奖品时 -->
      <template v-if="MyPrizesData">
        <div class="items-cover">
          <div class="items" v-for="(item, i) in MyPrizesData" :key="i">
            <div class="left">
              <img :src="item.cashCommodityImg" alt="" />
            </div>
            <div class="right">
              <h5>{{ item.cashCommodityName }}</h5>
              <p>活动奖品将在竞奖后2个工作日内寄出</p>
              <p>兑奖日期：{{ item.cashDate }}</p>
            </div>
            <div class="typeGold" v-show="item.cashType == '71' ? true : false">
              金卡兑奖
            </div>
            <div
              class="typeSilver"
              v-show="item.cashType == '72' ? true : false"
            >
              银卡兑奖
            </div>
          </div>
        </div>

        <!-- 分页设计 -->
        <div class="pages">
          <span @click="now--" :class="{ disabled: now == 1 }">上一页</span>
          <span
            v-for="n in totalPages"
            :key="n"
            @click="now = n"
            :class="{
              active: now == n ? true : false,
              display:
                (totalPages > 7 && n < 6 && now > 6) ||
                (totalPages > 7 && n > 7 && now < 7)
                  ? true
                  : false,
            }"
            >{{ n }}</span
          >
          <span @click="now++" :class="{ disabled: now == totalPages }"
            >下一页</span
          >
        </div>
        <!-- 加载 -->
        <div v-show="showLoading" class="loading">
          <img src="@/assets/images/loading.png" alt="" />
        </div>
      </template>
      <!-- 没有奖品时 -->
      <template v-if="!MyPrizesData">
        <div class="noPrizess">
          <img src="@/assets/images/noPrizes_03.png" alt="" />
          <p>暂无奖品</p>
        </div>
      </template>
      <!-- 关闭按钮 -->
      <div class="close" @click="hidePrizes">
        <img src="@/assets/images/close_03.png" alt="" />
      </div>
      <!-- 测试切换奖品状态 -->
      <!-- <div class="btn" @click="changePrizes">显示隐藏</div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [{ type: "gold" }, { type: "silver" }],
      MyPrizesData: null,
      now: 1, //当前显示的页码编号
      totalPages: null, //页码总数
      showLoading: false, // 显示加载,
      userInfo: null,
    };
  },

  watch: {
    now() {
      this.getUserPrizesData(this.now);
      console.log("页码变化了");
    },
    // 侦听exchangeTimes是否变化，变化了重新申请数据
    "$store.state.exchangeTimes"() {
      this.getUserPrizesData(1);
    },
  },
  mounted() {
    this.getQuery();
  },
  methods: {
    getQuery() {
      this.showLoading = true;
      // 获取网页的url信息
      /*
       http://101.33.251.244:5503/
       ?userId=zhangsan&openId=123543&codeVal=ccxcx02&activityId=11&hasDraw=1"
        */
      // 获取页面顶部的地址栏信息window.location
      var resUrl = window.location.search;
      console.log("Myprizes页面获取网址参数：", window.location);
      //截取"?"字符之后的字符串
      let stringUrl = resUrl.slice(1);
      // console.log("截取到的字符串：", stringUrl);
      // 用“&”把字符串分割成数组
      let arrSplit = stringUrl.split("&");
      // console.log("stringUrl分割后得到的数组：", arrSplit);
      if (arrSplit.length == 6) {
        // 合成新对象
        let urlInfo = {
          userId: null,
          openId: null,
          codeVal: null,
          activityId: null,
          hasDraw: null,
          factoryId: null,
        };

        for (var i = 0; i < arrSplit.length; i++) {
          // 获取“=”的下标
          let resNum = arrSplit[i].indexOf("=") + 1;
          let res = arrSplit[i].slice(resNum);
          // urlInfo添加参数
          if (i == 0) {
            urlInfo.userId = res;
          } else if (i == 1) {
            urlInfo.openId = res;
          } else if (i == 2) {
            urlInfo.codeVal = res;
          } else if (i == 3) {
            urlInfo.activityId = res;
          } else if (i == 4) {
            urlInfo.hasDraw = parseInt(res);
          } else if (i == 5) {
            urlInfo.factoryId = res;
          }
        }
        this.userInfo = urlInfo;
        // console.log("Myprizes页面生成的urlInfo：", this.userInfo);
        this.getUserPrizesData(1);
      } else {
        this.showLoading = false;
      }
    },
    // 获取我的奖品
    getUserPrizesData(pageIndex) {
      let that = this;
      that.showLoading = true;
      // http://jzh5api.zhenjiatong.com/activityV3/user/myCashCommodity
      let url = "/activityV3/user/myCashCommodity";
      let data = {
        openId: that.userInfo.openId,
        userId: that.userInfo.userId,
        factoryId: that.userInfo.factoryId,
        activityId: that.userInfo.activityId,
        pageNo: pageIndex,
        pageSize: 3,
      };
      that.$http
        .post(url, data)
        .then(function (res) {
          let wordCardCountList = res.data.data.responseMap.wordCardCountList;
          if (wordCardCountList.length > 0) {
            that.MyPrizesData = res.data.data.responseMap.wordCardCountList;
            that.totalPages = res.data.data.responseMap.pageVO.totalPage;
            that.showLoading = false;
          } else {
            that.showLoading = false;
            return;
          }
          console.log(
            "网络请求结果MyPrizesData成功：",
            res.data.data.responseMap
          );
        })
        .catch(function (err) {
          that.showLoading = false;
          console.log("网络请求结果MyPrizesData失败：", err);
        });
    },
    // 隐藏奖品-测试
    hidePrizes() {
      this.$emit("showMyPrizes");
    },
    // 切换显示奖品
    changePrizes() {
      this.havePrizes = !this.havePrizes;
    },
  },
};
</script>

<style lang="scss" scoped>
.marks {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}
.prizes {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  background-image: linear-gradient(#ffdcdc, #f9f1f1);
  border-top-right-radius: 0.42rem;
  border-top-left-radius: 0.42rem;

  h3 {
    text-align: center;
    font-size: 0.6rem;
    color: #750606;
    margin: 0.6rem;
  }
  .items-cover {
    width: 9.85rem;
    height: 11.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    margin: 0.34rem auto;
    margin-bottom: 0;
    // background-color: pink;
    .items {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding-top: 0.8rem;
      margin-bottom: 0.33rem;
      border-radius: 0.42rem;
      background-color: #fff;
      img {
        display: block;
        width: 2.12rem;
        height: 2.12rem;
        border-radius: 0.2rem;
        margin-right: 0.38rem;
      }
      h5 {
        font-size: 0.46rem;
        margin-bottom: 0.2rem;
        padding-right: 0.1rem;
      }
      p {
        font-size: 0.3rem;
        margin-bottom: 0.15rem;
        color: #858585;
      }
      .typeGold,
      .typeSilver {
        position: absolute;
        top: 0;
        right: 0;
        width: 1.93rem;
        height: 0.73rem;
        line-height: 0.73rem;
        border-top-right-radius: 0.42rem;
        border-bottom-left-radius: 0.42rem;
        text-align: center;
        font-size: 0.3rem;
      }
      .typeGold {
        background-image: linear-gradient(90deg, #ffe83e, #fcb31a);
        color: #ab3a03;
      }
      .typeSilver {
        background-image: linear-gradient(90deg, #f3f3f4, #cecece);
        color: #666666;
      }
    }
  }

  // 分页设计
  .pages {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    span {
      font-size: 12px;
      border: 1px solid transparent;
      background-color: #fff;
      border-radius: 0.05rem;
      padding: 0.15rem;
      color: #4e6ef2;
      margin: 0 0.15rem;
    }
    .active {
      background-color: #4e6ef2;
      color: #fff;
    }
    .disabled {
      color: #bbb;
      pointer-events: none;
    }
    .display {
      display: none;
    }
  }
  // 加载图片
  .loading {
    position: absolute;
    top: 8rem;
    left: 0;
    display: flex;
    width: 100%;
    height: 2rem;
    justify-content: center;
    align-items: center;
    z-index: 10;
    img {
      width: 2rem;
      height: 2rem;
      animation: rotate 2s linear infinite;
      @keyframes rotate {
        from {
          transform: rotateZ(0deg);
        }
        to {
          transform: rotateZ(360deg);
        }
      }
    }
  }
  // 关闭按钮
  .close {
    position: absolute;
    top: 0.68rem;
    right: 0.68rem;
    width: 0.33rem;
    height: 0.3rem;
    img {
      width: 100%;
    }
  }
  // 没有奖品时
  .noPrizess {
    width: 100%;
    margin: 3.2rem 0;
    color: #999999;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      display: block;
      width: 6.7rem;
      height: 4.83rem;
      margin-bottom: 0.65rem;
    }
  }
  .btn {
    width: 2rem;
    margin: 0.4rem auto;
    padding: 0.2rem;
    border: 1px solid #f7c4db;
    border-radius: 0.2rem;
    text-align: center;
    color: #fe915f;
  }
}
</style>
