import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    haveTimes: 0,//抽奖次数
    urlInfo: null,//web1传递过来的用户信息
    exchangeTimes: 0,//给兑奖增加计数器，用于侦听是否有兑奖事件，如果有那么重新刷新个人奖品信息
  },
  getters: {
  },
  mutations: {
    // 改变兑奖计数器
    changeExchangeTimes(state) {
      state.exchangeTimes++
      console.log('用户兑奖了', state.exchangeTimes);
    },
    // 抽奖次数+num
    haveTimesAdd(state, num) {
      state.haveTimes += num
    },
    // 抽奖次数-1/置零
    haveTimesSub(state) {
      state.haveTimes = 0
    },
    // 改变web1传递过来的用户信息
    saveUrlInfo(state, info) {
      state.urlInfo = info
      // console.log('VUEX接收web1传递过来的用户信息:', state.urlInfo);
    }
  },
  actions: {
  },
  modules: {
  }
})
