var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-prizes marks"},[_c('div',{staticClass:"prizes"},[_c('h3',[_vm._v("我的奖品")]),(_vm.MyPrizesData)?[_c('div',{staticClass:"items-cover"},_vm._l((_vm.MyPrizesData),function(item,i){return _c('div',{key:i,staticClass:"items"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":item.cashCommodityImg,"alt":""}})]),_c('div',{staticClass:"right"},[_c('h5',[_vm._v(_vm._s(item.cashCommodityName))]),_c('p',[_vm._v("活动奖品将在竞奖后2个工作日内寄出")]),_c('p',[_vm._v("兑奖日期："+_vm._s(item.cashDate))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.cashType == '71' ? true : false),expression:"item.cashType == '71' ? true : false"}],staticClass:"typeGold"},[_vm._v(" 金卡兑奖 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.cashType == '72' ? true : false),expression:"item.cashType == '72' ? true : false"}],staticClass:"typeSilver"},[_vm._v(" 银卡兑奖 ")])])}),0),_c('div',{staticClass:"pages"},[_c('span',{class:{ disabled: _vm.now == 1 },on:{"click":function($event){_vm.now--}}},[_vm._v("上一页")]),_vm._l((_vm.totalPages),function(n){return _c('span',{key:n,class:{
            active: _vm.now == n ? true : false,
            display:
              (_vm.totalPages > 7 && n < 6 && _vm.now > 6) ||
              (_vm.totalPages > 7 && n > 7 && _vm.now < 7)
                ? true
                : false,
          },on:{"click":function($event){_vm.now = n}}},[_vm._v(_vm._s(n))])}),_c('span',{class:{ disabled: _vm.now == _vm.totalPages },on:{"click":function($event){_vm.now++}}},[_vm._v("下一页")])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLoading),expression:"showLoading"}],staticClass:"loading"},[_c('img',{attrs:{"src":require("@/assets/images/loading.png"),"alt":""}})])]:_vm._e(),(!_vm.MyPrizesData)?[_vm._m(0)]:_vm._e(),_c('div',{staticClass:"close",on:{"click":_vm.hidePrizes}},[_c('img',{attrs:{"src":require("@/assets/images/close_03.png"),"alt":""}})])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"noPrizess"},[_c('img',{attrs:{"src":require("@/assets/images/noPrizes_03.png"),"alt":""}}),_c('p',[_vm._v("暂无奖品")])])
}]

export { render, staticRenderFns }