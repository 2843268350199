<template>
  <div id="app">
    <!-- 引入头部组件 -->
    <my-header></my-header>
    <!-- 路由跳转 -->
    <router-view></router-view>
  </div>
</template>

<script>
import MyHeader from "./components/MyHeader.vue";
export default {
  components: { MyHeader },
  data() {
    return {
      userInfo: null,
    };
  },
  mounted() {
    this.getQuery();
  },

  methods: {
    // 获取网页的url信息

    getQuery() {
      //静态，测试
     //var resUrl = "http://101.33.251.244:5503/?userId=zhangsan&openId=123543&codeVal=ccxcx02&activityId=11&hasDraw=1"
      //var resUrl =" http://jzh5.zhenjiatong.com/?userId=75026&openId=ob0csuLtKhJ3rIwbn-iNEDJJfedU&codeVal=9fb100871358006417482555&activityId=12628&hasDraw=0&factoryId=66114"
      // 获取页面顶部的地址栏信息window.location
        var resUrl = window.location.search;
      // console.log("获取网址参数：", window.location);
      //截取"?"字符之后的字符串
      let stringUrl = resUrl.slice(1);
      // console.log("截取到的字符串：", stringUrl);
      // 用“&”把字符串分割成数组
      let arrSplit = stringUrl.split("&");
      // console.log("stringUrl分割后得到的数组：", arrSplit);
      if (arrSplit.length == 6) {
        // 合成新对象
        let urlInfo = {
          userId: null,
          openId: null,
          codeVal: null,
          activityId: null,
          hasDraw: null,
          factoryId: null,
        };

        for (var i = 0; i < arrSplit.length; i++) {
          // 获取“=”的下标
          let resNum = arrSplit[i].indexOf("=") + 1;
          let res = arrSplit[i].slice(resNum);
          // urlInfo添加参数
          if (i == 0) {
            urlInfo.userId = res;
          } else if (i == 1) {
            urlInfo.openId = res;
          } else if (i == 2) {
            urlInfo.codeVal = res;
          } else if (i == 3) {
            urlInfo.activityId = res;
          } else if (i == 4) {
            urlInfo.hasDraw = parseInt(res);
          } else if (i == 5) {
            urlInfo.factoryId = res;
          }
        }
        // console.log("新生成的urlInfo：", urlInfo);
        this.userInfo = urlInfo;
        // 获取用户抽奖次数，并保存在vuex中
        let num = urlInfo.hasDraw;
        // console.log("抽奖次数：", num);
        if (num === 0 || num === 1) {
          // haveTimes保存在vuex中
          this.$store.commit("haveTimesAdd", num);
          this.$store.commit("saveUrlInfo", urlInfo);
        } else {
          // alert("参数错误！");
          this.$router.replace("/404");
          // this.$store.commit("haveTimesAdd", 0);
        }
      } else {
        // 禁止无参数情况下直接访index页面
        this.$router.replace("/404");
      }
      // console.log("VUEX用户的抽奖机会：", this.$store.state.haveTimes);
    },
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
#app {
  margin: 0 auto;
}
</style>
