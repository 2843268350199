<template>
  <div class="my-header">
    <div class="header">
      <!-- 可复美模块 -->
      <div class="header-one">
        <div class="header-one-left" @click="showMyPrizes">
          <span>我的</span>
          <span>奖品</span>
        </div>
        <img src="@/assets/images/header-icon.png" alt="" />
        <div class="header-one-right" @click="showMyReadeMe">
          <span>活动</span>
          <span>规则</span>
        </div>
      </div>
      <!-- 集卡大挑战 -->
      <div class="header-two">
        <img src="@/assets/images/header-title.png" alt="" />
      </div>
      <!-- 次抛+洁面+械喷 -->
      <div class="header-three">
        <div class="header-three-subtitle">
          <span>次抛</span><span>/</span><span>洁面</span><span>/</span
          ><span>喷雾</span>
        </div>
      </div>
    </div>
    <!-- 显示我的奖品 -->
    <!-- ref="prizes" -->
    <my-prizes v-show="showPrizes" @showMyPrizes="showMyPrizes"></my-prizes>
    <!-- 显示活动规则 -->
    <read-me v-show="showReadMe" @showMyReadeMe="showMyReadeMe"></read-me>
  </div>
</template>

<script>
import MyPrizes from "./MyPrizes.vue";
import ReadMe from "./ReadMe.vue";
export default {
  components: { MyPrizes, ReadMe },
  data() {
    return {
      showPrizes: false, //是否显示“我的奖品”
      showReadMe: false,
    };
  },
  methods: {
    // 点击刷新用户数据
    // 显示我的奖品
    showMyPrizes() {
      this.showPrizes = !this.showPrizes;
      // this.$refs.prizes.getUserPrizesData(1);
    },
    // 显示活动规则
    showMyReadeMe() {
      this.showReadMe = !this.showReadMe;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;
  height: 5.32rem;
  background-image: url("@/assets/images/header-bgNewLast.png");
  background-size: 100% 100%;
  border-top: 1px solid #79049a;
  .header-one {
    display: flex;
    color: #fff;
    // margin-top: 1.38rem;
    margin-top: 0.24rem;
    justify-content: space-between;
    align-items: center;
    .header-one-left,
    .header-one-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 1.54rem;
      height: 1.18rem;
      background-color: pink;
      font-size: 0.36rem;
    }
    .header-one-left {
      padding-right: 0.18rem;
      border-top-right-radius: 0.59rem;
      border-bottom-right-radius: 0.59rem;
      background-image: linear-gradient(132deg, #9408a9, #f91b62);
    }
    .header-one-right {
      padding-left: 0.18rem;
      border-top-left-radius: 0.59rem;
      border-bottom-left-radius: 0.59rem;
      background-image: linear-gradient(228deg, #9408a9, #f91b62);
    }
    img {
      width: 2.44rem;
      height: 1.32rem;
    }
  }
  .header-two,
  .header-three {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.33rem;
    height: 1.44rem;
    img {
      display: block;
      width: 7.53rem;
      height: 100%;
    }
  }
  .header-three {
    height: 0.87rem;
    .header-three-subtitle {
      width: 5.25rem;
      height: 100%;
      background-image: url("@/assets/images/header-subtitle.png");
      background-size: 100% 100%;
      text-align: center;
      line-height: 0.87rem;
      color: #fff;
      font-size: 0.36rem;
      span {
        margin: 0 0.07rem;
      }
    }
  }
}
</style>
